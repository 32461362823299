<template>
    <section class="position-relative">
        <div class="custom-scroll overflow-auto pt-3 pr-3" style="height:calc(100vh - 135px);">
            <timercomponent v-if="cargandoTerminado" v-model="tipo_fecha" />
            <div class="row mx-0 py-5" />
            <div class="d-flex position-relative mx-0">
                <cargando v-if="cargandoBarras" />
                <div class="col bg-white br-12 px-0">
                    <p class="text-general mt-3 text-center f-600 f-18">
                        {{ $config.vendedor }}
                    </p>
                    <echart ref="tiendas_graph" :options="tiendas_graph" :height="heightCalTiendas" />
                    <div v-if="tiendas_graph.dataset.source.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-col bg-whiteor:#ffffff;z-index:1;" br-12 />
                    <div v-if="tiendas_graph.dataset.source.length == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
                <div class="col bg-white br-12 px-0 mx-3">
                    <p class="text-general mt-3 text-center f-600 f-18">
                        {{ $config.cliente + 's' }}
                    </p>
                    <echart ref="clientes_graph" :options="clientes_graph" :height="heightCalClientes" />
                    <div v-if="clientes_graph.dataset.source.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-col bg-whiteor:#ffffff;z-index:1;" br-12 />
                    <div v-if="clientes_graph.dataset.source.length == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
                <div class="col bg-white br-12 px-0">
                    <p class="text-general mt-3 text-center f-600 f-18">
                        Productos y dinero en el inventario
                    </p>
                    <echart ref="inventario_graph" :options="inventario_graph" :height="heightCalProductos" />
                    <div v-if="inventario_graph.dataset.source.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                    <div v-if="inventario_graph.dataset.source.length == 0" class="img-sindatos text-center">
                        <img class="obj-cover" src="/img/otros/no_datos.svg" />
                    </div>
                </div>
            </div>
            <div class="row mx-0 bg-white br-12 mt-4">
                <div class="col-12">
                    <div class="row mx-2 mt-5 px-4">
                        <div class="col-12 py-3">
                            <p class="text-general f-18 f-600">
                                Comparativa entre cedis por {{ $config.vendedor }} y clientes de las ultimas 12 semanas
                            </p>
                        </div>
                        <div class="col mt-4 pr-0 border-bottom">
                            <p class="text-general f-18 f-600 text-center">
                                {{ $config.vendedor }}
                            </p>
                            <div class="row mx-0 pr-4 justify-content-around mt-4">
                                <div
                                v-for="(filtro, f) in filtro_tiendas" :key="f"
                                :class="filtro.id == filtro_act ? 'filtro-activo text-general' : 'filtro'"
                                class="col px-2 d-middle-center cr-pointer"
                                @click="filtro_act = filtro.id"
                                >
                                    <el-tooltip placement="bottom" effect="light" :content="filtro.mensaje">
                                        <i :class="`${filtro.icono} f-${filtro.size}`" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="col mt-4 pl-0 border-bottom">
                            <p class="text-general f-18 f-600 text-center">
                                {{ $config.cliente + 's' }}
                            </p>
                            <div class="row mx-0 pl-4 justify-content-around mt-4">
                                <div
                                v-for="(filtro, f1) in filtro_clientes" :key="f1"
                                :class="filtro.id == filtro_act ? 'filtro-activo text-general' : 'filtro'"
                                class="col px-2 d-middle-center cr-pointer"
                                @click="filtro_act = filtro.id"
                                >
                                    <el-tooltip placement="bottom" effect="light" :content="filtro.mensaje">
                                        <i :class="`${filtro.icono} f-${filtro.size}`" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row mx-0 my-5 text-general position-relative justify-center">
                        <cargando v-if="cargandoComparativas" />
                        <p class=" col-12 text-center mb-4f-18 f-600">
                            {{ nombre_filtro }}
                        </p>
                        <div class="col-12">
                            <echart ref="echart" :options="cedisComparativaAcumulado" />
                            <div v-if="cedisComparativaAcumulado.dataset.source.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="cedisComparativaAcumulado.dataset.source.length == 0" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                        <div class="col-12">
                            <echart ref="echart" :height="total_cedis" :options="tiendas_nuevas" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Select del rango -->
            <div class="row mx-0 align-items-center justify-content-start my-4">
                <div class="col-auto d-middle text-general f-15">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="small">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="small"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-general f-14 px-2" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoInforme" mini />
            </div>
            <!-- Select del rango -->
            <div class="row mx-0 my-5 bg-white br-12">
                <div class="col-12 my-3">
                    <p class="text-left f-18 text-general f-600">
                        Utilidad
                    </p>
                </div>
                <div class="col my-3">
                    <echart :options="utilidad" />
                </div>
            </div>
            <!-- Barras -->
            <div class="row mx-0 mb-5 justify-center">
                <div class="col bg-white br-12 mt-auto">
                    <echart v-if="ventas_total > 0" :options="optionsVentas" />
                    <div v-else class="">
                        <div class="position-relative pt-3 f-20 text-center" style="color:#0892DD">
                            <p>Ventas</p>
                            <p>0%</p>
                        </div>
                        <div class="position-relative f-20 text-center">
                            <img src="/img/otros/cilueta_torta.png" alt="" />
                            <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                        </div>
                    </div>
                    <!-- Ventas -->
                    <el-popover
                    v-model="visibleVentas"
                    popper-class="popoverEdit"
                    placement="top"
                    trigger="manual"
                    >
                        <div class="row justify-center mx-0 position-relative">
                            <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleVentas = !visibleVentas" />
                            Ventas
                        </div>
                        <div v-for="(venta, r) in ventas" :key="r" class="row mx-2 justify-content-center py-2">
                            <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${venta.color}`" />
                            <div class="col-5">
                                {{ venta.name }}
                            </div>
                            <div class="col px-0 text-right">
                                {{ separadorNumero(venta.value) }}
                            </div>
                            <div class="col-3 text-right px-1">
                                {{ agregarSeparadoresNumero(venta.percent,1) }}%
                            </div>
                        </div>
                        <div v-if="ventas.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleVentas = !visibleVentas">
                            Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                        </div>
                    </el-popover>
                    <!-- Ventas -->
                </div>
                <div class="col mx-3 bg-white br-12">
                    <echart v-if="entregas_total > 0" :options="optionsEntregas" />
                    <div v-else class="">
                        <div class="position-relative pt-3 f-20 text-center" style="color:#284367">
                            <p>Entregas</p>
                            <p>0%</p>
                        </div>
                        <div class="position-relative f-20 text-center">
                            <img src="/img/otros/cilueta_torta.png" alt="" />
                            <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                        </div>
                    </div>
                    <!-- Entregas -->
                    <el-popover
                    v-model="visibleEntregas"
                    popper-class="popoverEdit"
                    placement="top"
                    trigger="manual"
                    >
                        <div class="row justify-center mx-0 position-relative">
                            <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleEntregas = !visibleEntregas" />
                            Entregas
                        </div>
                        <div v-for="(entrega, r) in entregas" :key="r" class="row mx-2 justify-content-center py-2">
                            <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${entrega.color}`" />
                            <div class="col-5">
                                {{ entrega.name }}
                            </div>
                            <div class="col px-0 text-right">
                                {{ agregarSeparadoresNumero(entrega.value) }}
                            </div>
                            <div class="col-3 text-right px-1">
                                {{ agregarSeparadoresNumero(entrega.porcentaje,1) }}%
                            </div>
                        </div>
                        <div v-if="entregas.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleEntregas = !visibleEntregas">
                            Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                        </div>
                    </el-popover>
                    <!-- Entregas -->
                </div>
                <div class="col bg-white br-12">
                    <echart v-if="activos_total > 0" :options="optionsActivos" />
                    <div v-else class="">
                        <div class="position-relative pt-3 f-20 text-center" style="color:#03D6BC">
                            <p>{{ $config.vendedor + 's' }} activos </p>
                            <p>0%</p>
                        </div>
                        <div class="position-relative f-20 text-center">
                            <img src="/img/otros/cilueta_torta.png" alt="" />
                            <div class="position-absolute w-100 h-100" style="background: #ffffffcf;top:0 " />
                        </div>
                    </div>
                    <!-- Leecheros activos -->
                    <el-popover
                    v-model="visibleTenderoCancelacion"
                    popper-class="popoverEdit"
                    placement="top"
                    trigger="manual"
                    >
                        <div class="row justify-center mx-0 position-relative">
                            <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleTenderoCancelacion = !visibleTenderoCancelacion" />
                            {{ $config.vendedor }} activos
                        </div>
                        <div v-for="(activo, r) in activos" :key="r" class="row mx-2 justify-content-center py-2">
                            <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                            <div class="col-5">
                                {{ activo.name }}
                            </div>
                            <div class="col px-0 text-right">
                                {{ agregarSeparadoresNumero(activo.value) }}
                            </div>
                            <div class="col-3 text-right px-1">
                                {{ agregarSeparadoresNumero(activo.porcentaje,1) }}%
                            </div>
                        </div>
                        <div v-if="activos.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleTenderoCancelacion = !visibleTenderoCancelacion">
                            Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                        </div>
                    </el-popover>
                    <!-- Leecheros activos -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Comparativa from "../../services/cedis/comparativa";
Vue.component('echarts',echarts);
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
var itemStyle = {
    opacity: 0.8,
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'rgba(0, 0, 0, 0.5)'
};

export default {
    data(){
        let self = this
        return {
            tiendas_graph: {
                legend: {
                    top: 25,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                        overflow: 'truncate',
                        color: '#707070',
                    },
                    data: ['Activos última semana', 'Activos último mes', 'Perdidos último mes']
                },
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].name}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-brightness f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.activas_semana)}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.activas_mes)}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.perdidas)}</span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: '#DFDFDF'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DFDFDF'
                            }
                        },
                        axisLabel: {
                            color: '#a4a8b4',
                        }
                    },
                ],
                yAxis: {
                    type: 'category',
                    // data: ['Rocio', 'Aguachica', 'Ciudad De mexico'],
                    axisLine: {
                        lineStyle: {
                            color: '#DFDFDF'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#6D6B6B',
                        interval: 0
                    }
                },
                color: [
                    '#000000',
                    '#707070',
                    '#DBDBDB',
                ],
                dataset:{
                    dimensions:['nombre', 'activas_semana','activas_mes','perdidas'],
                    source: []
                },
                series: [
                    {
                        name: 'Activos última semana',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Activos último mes',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Perdidos último mes',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            clientes_graph: {
                legend: {
                    top: 25,
                    left: 'center',
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                        color: '#5D5D5D',
                    },
                    data: ['Activos última semana', 'Activos último mes', 'Perdidos último mes']
                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].name}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-brightness f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.activos_semana)}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.activos_mes)}</span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.perdidos)}</span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: '#DFDFDF'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DFDFDF'
                            }
                        },
                        axisLabel: {
                            color: '#a4a8b4',
                        }
                    },
                ],
                yAxis: {
                    type: 'category',
                    // data: ['Rocio', 'Aguachica', 'Ciudad De mexico'],
                    axisLine: {
                        lineStyle: {
                            color: '#DFDFDF'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#6D6B6B',
                        interval: 0
                    }
                },
                color: [
                    '#000000',
                    '#707070',
                    '#DBDBDB',
                ],
                dataset:{
                    dimensions:['nombre', 'activos_semana','activos_mes','perdidos'],
                    source: []
                },
                series: [
                    {
                        name: 'Activos última semana',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Activos último mes',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Perdidos último mes',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            inventario_graph: {
                legend: {
                    top: 25,
                    left: 'center',
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 12,
                        color: '#5D5D5D',
                        padding: [3, 0, 0, 0]
                    },
                    data: ['Productos', 'Dinero']
                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].name}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.cantidad)}</span>`  + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.separadorNumero(obj[1].data.dinero)}</span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'value',
                        boundaryGap: true,
                    },
                    {
                        type: 'value',
                        boundaryGap: true,
                        show:false
                    }
                ],
                yAxis: [
                    {
                        // name: 'Valor',
                        type: 'category',
                        scale: true,
                        axisLabel: {
                            color: '#6D6B6B',
                            interval: 0
                        }
                    },
                    {
                        // name: 'Cantidad',
                        type: 'category',
                        scale: true,
                        axisLabel: {
                            color: '#6D6B6B',
                            interval: 0
                        }
                    }
                ],
                color: [
                    '#000000',
                    '#FF1720',
                ],
                dataset:{
                    dimensions:['nombre', 'cantidad','dinero'],
                    source: []
                },
                series: [
                    {
                        name: 'Productos',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Dinero',
                        stack: 'money',
                        type: 'line',
                        xAxisIndex: 1,
                        symbol: 'circle',
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                                lineStyle: {
                                    color: colorGeneral,
                                    width: 1
                                },
                            }
                        },
                    },
                ]
            },
            filtro_tiendas: [
                {
                    icono: 'icon-store-plus',
                    size: 22,
                    id: 1,
                    mensaje: 'Leecheros Nuevos'
                },
                {
                    icono: 'icon-store',
                    size: 22,
                    id: 2,
                    mensaje: 'Leecheros Activos'
                },
                {
                    icono: 'icon-currency-usd-circle',
                    size: 20,
                    id: 3,
                    mensaje: 'Ventas'
                },
                {
                    icono: 'icon-receipt',
                    size: 22,
                    id: 4,
                    mensaje: 'Pedidos'
                },
                {
                    icono: 'icon-cash',
                    size: 17,
                    id: 5,
                    mensaje: 'Valor Promedio en Pedidos'
                },
                {
                    icono: 'icon-package-variant-closed',
                    size: 22,
                    id: 6,
                    mensaje: 'Productos Vendidos'
                },
                {
                    icono: 'icon-truck-fast-outline',
                    size: 20,
                    id: 13,
                    mensaje: 'Despachos'
                }
            ],
            filtro_act: 1,
            filtro_clientes: [
                {
                    icono: 'icon-account-plus',
                    size: 22,
                    id: 7,
                    mensaje: 'Clientes Nuevos'
                },
                {
                    icono: 'icon-account-check',
                    size: 22,
                    id: 8,
                    mensaje: 'Clientes Activos'
                },
                {
                    icono: 'icon-currency-usd-circle',
                    size: 20,
                    id: 9,
                    mensaje: 'Ventas'
                },
                {
                    icono: 'icon-receipt',
                    size: 22,
                    id: 10,
                    mensaje: 'Pedidos'
                },
                {
                    icono: 'icon-cash',
                    size: 17,
                    id: 11,
                    mensaje: 'Valor Promedio en pedidos'
                },
                {
                    icono: 'icon-package-variant-closed',
                    size: 22,
                    id: 12,
                    mensaje: 'Productos Vendidos'
                },
                {
                    icono: 'icon-truck-fast-outline',
                    size: 20,
                    id: 14,
                    mensaje: 'Despachos'
                }
            ],
            cedisComparativaAcumulado: {
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: [
                    {
                        type: 'category',
                        /* boundaryGap: true, */
                        show: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        axisLabel: {
                            formatter: value => {
                                if ([3,5,9,11].includes(self.filtro_act)){
                                    return `${this.moneda.sigla} ${self.separadorNumero(value, 0, false)}`
                                } else {
                                    return `${value}`
                                }
                            }
                        }
                        // name: 'Valor',
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'semanas'],
                    source: []
                },
                series: [
                    {
                        name: 'Cedis',
                        type: 'bar',
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            tiendas_nuevas: {
                tooltip: {},
                title: [],
                singleAxis: [],
                series: []
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            value: '',
            value1: '',
            utilidad:{
                color: color,
                legend: {
                    type: 'scroll',
                    textStyle: {
                        color: '#000',
                        fontSize: 16
                    },

                },
                grid: {top: '30%'},
                tooltip: {
                    padding: 10,
                    backgroundColor: '#222',
                    borderColor: '#777',
                    borderWidth: 1,
                    formatter: function(obj){
                        var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + obj.seriesName
                        + '</div>'
                        + 'Utilidad：' + self.separadorNumero(value[1]) + '<br>'
                        + 'Ventas：' + self.separadorNumero(value[0]) + '<br>'
                        + self.agregarSeparadoresNumero(value[2],1) + '%<br>'
                    }
                },
                xAxis: {
                    type: 'value',
                    name: 'Utilidad',
                    nameGap: 16,
                    // max: ,
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    name: 'Ventas',
                    nameLocation: 'end',
                    // nameGap: 20,
                    splitLine: {
                        show: false
                    }
                },
                series: []
            },
            visibleVentas: false,
            visibleEntregas: false,
            visibleTenderoCancelacion: false,
            visibleClienteCancelacion: false,
            ventas: [],
            entregas: [],
            activos: [],
            cancelacionCliente: [],
            optionsVentas :{
                title: {
                    text: 'Ventas',
                    // subtext: `${this.agregarSeparadoresNumero(this.porcentajes.rechazos)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#0d4dff',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 5,
                },
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.separadorNumero(params.value.dinero)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','dinero'],
                    source:[],
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            optionsEntregas :{
                title: {
                    text: 'Entregas',
                    // subtext: `${this.agregarSeparadoresNumero(this.porcentajes.tiempos)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#0d4dff',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 5,
                },
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Entregas',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            optionsActivos :{
                title: {
                    text: 'Leecheros activos',
                    // subtext: `${this.agregarSeparadoresNumero(this.porcentajes.cancelacion_tienda)}%`,
                    textStyle: {
                        color: '#000000',
                        fontSize: 20,
                        fontWeight: 'normal'
                    },
                    subtextStyle: {
                        fontSize: 20,
                        color: '#707070',
                        fontWeight: 'bold'
                    },
                    left: "center",
                    top: 5,
                },
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Leecheros activos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            ventas_total: 0,
            entregas_total: 0,
            activos_total: 0,
            tipo_fecha: 1,
            primeraVez: true,
            cargandoComparativas: true,
            cargandoBarras: true,
            cargandoInforme: true,
            total_cedis: "40px",
            barrasCreated: null,
            barrasEntregas: null,
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            moneda:'cedis/moneda',
        }),
        nombre_filtro(){
            if ((this.filtro_act >= 1 && this.filtro_act < 7) || this.filtro_act == 13 ){
                return this.filtro_tiendas.find(d => d.id == this.filtro_act).mensaje
            }else{
                return this.filtro_clientes.find(d => d.id == this.filtro_act).mensaje
            }
        },
        cargandoTerminado(){
            return  !this.cargandoBarras &&
                    !this.cargandoComparativas &&
                    !this.cargandoInforme
        },
        heightCalTiendas(){
            const GRAPH = this.tiendas_graph.dataset.source;
            if(GRAPH == 0){ return '300' }
            let height = 300 + GRAPH.length * 16;
            return `${height}px`
        },
        heightCalClientes(){
            const GRAPH = this.clientes_graph.dataset.source;
            if(GRAPH == 0){ return '300' }
            let height = 300 + GRAPH.length * 16;
            return `${height}px`
        },
        heightCalProductos(){
            const GRAPH = this.inventario_graph.dataset.source;
            if(GRAPH == 0){ return '300' }
            let height = 300 + GRAPH.length * 16;
            return `${height}px`
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
        },
        filtro_act(val){
            if(val == null) return
            this.comparativas();
        },
        tipo_fecha(){
            this.calcularBarras()
            this.comparativas()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        },
        tiendas_graph: {
            handler(){
                setTimeout(() => {
                    this.$refs.tiendas_graph.handleResize();
                }, 0);
            },
            deep: true
        },
        clientes_graph: {
            handler(){
                setTimeout(() => {
                    this.$refs.clientes_graph.handleResize();
                }, 0);
            },
            deep: true
        },
        inventario_graph: {
            handler(){
                setTimeout(() => {
                    this.$refs.inventario_graph.handleResize();
                }, 0);
            },
            deep: true
        }

    },
    mounted(){
        this.montar()

        this.filtro_tiendas[0].mensaje = `${this.$config.vendedor} Nuevos`;
        this.filtro_tiendas[1].mensaje = `${this.$config.vendedor} Activos`;
        this.filtro_clientes[0].mensaje = `${this.$config.cliente} Nuevos`;
        this.filtro_clientes[1].mensaje = `${this.$config.cliente} Activos`;
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoBarras = true
                this.cargandoComparativas = true
            }
            this.cargandoInforme = true
        },
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.graficas_barras()
                        await this.comparativas()
                        await this.informe()
                    }, 1000);
                }else{
                    await this.graficas_barras()
                    await this.comparativas()
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        async comparativas(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    tipo: this.filtro_act,
                    tipoFecha: this.tipo_fecha,
                }
                this.cargandoComparativas = true

                const {data} = await Comparativa.comparativas(params)
                let self = this
                //console.log(data, 'toda la data');

                let cedisOrdenado = []

                cedisOrdenado = _.orderBy(data.cedis,'semanas','desc')

                this.cedisComparativaAcumulado.dataset.source = cedisOrdenado
                this.total_cedis = `${data.cedis.length * 200}px`;
                this.tiendas_nuevas.title = []
                this.tiendas_nuevas.singleAxis = []
                this.tiendas_nuevas.series = []

                this.tiendas_nuevas.tooltip = {
                    position: 'top',
                    trigger: 'item',
                    formatter: function(params){
                        if ([3,5,9,11].includes(self.filtro_act)){
                            return `${params.name} </br>
                        ${self.separadorNumero(params.value[1])} ${self.nombre_filtro}`
                        } else {
                            return `${params.name} </br>
                        ${self.agregarSeparadoresNumero(params.value[1])} ${self.nombre_filtro}`
                        }
                    }
                }
                let maxs = []
                data.cedis.forEach((cedis, idx) => {
                    let max = Math.max(...cedis.valores.map(d=>d.total))
                    maxs.push(max)
                })
                let max = Math.max(...maxs)
                // console.log(maxs,'maxs');
                console.log(max,'max');

                data.cedis.forEach((cedis, idx) => {

                    //console.log(cedis, 'dentro del foreach');
                    this.tiendas_nuevas.title.push({
                        textBaseline: 'middle',
                        top: (idx + 0.1 ) * 96 / data.cedis.length + 5 + '%',
                        text: cedis.nombre,
                        textStyle: {
                            color: '#000000'
                        }
                    });

                    // let max = Math.max(...cedis.valores.map(d=>d.total))
                    let valores = cedis.valores.map((dataItem,i) => {
                        let porcentaje = max == 0 ? 0 : Math.round((dataItem.total / +max) * 100, 1)
                        let min = porcentaje == 0 ? 0 : porcentaje <= 5 ? 5 : porcentaje
                        return [i, dataItem.total,min]
                    });

                    this.tiendas_nuevas.singleAxis.push({
                        left: 300,
                        type: 'category',
                        boundaryGap: false,
                        data: data.semanas,
                        top: (idx * 96 / data.cedis.length + 5) + '%',
                        height: (100 / data.cedis.length - 10) + '%',
                        axisLabel: {
                            interval: 1
                        }
                    });

                    //console.log(valores,'cedis',cedis.nombre, max);

                    this.tiendas_nuevas.series.push({
                        singleAxisIndex: idx,
                        coordinateSystem: 'singleAxis',
                        type: 'scatter',
                        data: valores,
                        symbolSize: function(dataItem){
                            return dataItem[2] * 0.66;
                        },
                        itemStyle:{
                            normal:{
                                color: colorGeneral
                            }
                        },
                    });
                });
                setTimeout(() => {
                    this.$refs.echart.handleResize()
                }, 0);
            } catch (error){
                console.log(error)
            } finally {
                this.cargandoComparativas = false
            }

        },
        async graficas_barras(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                }
                const {data} = await Comparativa.graficas_barras(params)

                this.barrasCreated = data.pedidos_created
                this.barrasEntregas = data.pedidos_entregas

                this.calcularBarras()
            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargandoBarras = false
            }
        },
        calcularBarras(){
            try {
                let datos = this.tipo_fecha == 1 ? this.barrasEntregas : this.barrasCreated

                this.tiendas_graph.dataset.source = datos.tiendas_cedis
                this.clientes_graph.dataset.source = datos.clientes_cedis
                this.inventario_graph.dataset.source = datos.productos_cedis
            } catch (error){
                console.log(error);
            }
        },
        async informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                }
                this.loading = true
                const {data} = await Comparativa.informe(params)

                this.loading = false

                let ventas_local = []
                let entregas_local = []
                let activos_local = []
                this.ventas_total = data.ventas_total
                this.entregas_total = data.entregas_total
                this.activos_total = data.activos_total

                let ventas = data.ventas
                for (var i = 0; i < ventas.length; i++){
                    ventas_local.push({
                        value: ventas[i].dinero,
                        name: ventas[i].nombre,
                        percent: ventas[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.optionsVentas.dataset.source = data.ventas
                this.ventas = ventas_local

                let entregas = data.entregas
                for (var i = 0; i < entregas.length; i++){
                    entregas_local.push({
                        value: entregas[i].cantidad,
                        name: entregas[i].nombre,
                        porcentaje: entregas[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.optionsEntregas.dataset.source = data.entregas
                this.entregas = entregas_local

                let activos = data.activos
                for (var i = 0; i < activos.length; i++){
                    activos_local.push({
                        value: activos[i].cantidad,
                        name: activos[i].nombre,
                        porcentaje: activos[i].porcentaje,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.optionsActivos.dataset.source = data.activos
                this.activos = activos_local

                let utilidades = []

                let max = Math.max(...data.utilidad.map(d=>d.porcentaje))

                data.utilidad.map(d=>{
                    let porcentaje = max == 0 ? 0 : Math.round((d.porcentaje / +max) * 100, 1)
                    let min = porcentaje == 0 ? 0 : porcentaje <= 17 ? 17 : porcentaje
                    utilidades.push({
                        name: d.nombre,
                        type: 'scatter',
                        itemStyle: itemStyle,
                        data: [[d.valor_final, d.utilidad,d.porcentaje , min]],
                        symbolSize: function(data){
                            return data[3] * 0.75;
                        },
                    })
                })
                this.utilidad.series = utilidades

            } catch (error){
                console.log(error);
            } finally {
                this.cargandoInforme = false
            }
        },
        async informes(){
            try {
                this.cargando(false)
                await this.informe()

            } catch (error){
                console.log(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.filtro{ color: #5D5D5D; }
.filtro-activo{ border-bottom: 1px solid var(--text-general); }
.border-bottom1{ border-bottom: 2px solid #707070; }
.br-12{ border-radius: 12px; }

.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
