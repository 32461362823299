<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return{
            view: 1,
            rutas: [
                {
                    ruta: 'admin.cedis.cupones.clientes.lista'
                },
                {
                    titulo: 'Estadisticas Clientes',
                    ruta: 'admin.cedis.cupones.clientes.dashboard'
                },
                {
                    titulo: 'Lista de cupones Leecheros',
                    ruta: 'admin.cedis.cupones.leecheros.lista'
                },
                {
                    titulo: 'Estadisticas Leecheros',
                    ruta: 'admin.cedis.cupones.leecheros.dashboard'
                },
            ]
        }
    },
    created(){
        this.rutas[0].titulo = `Lista de cupones ${ this.$config.cliente + 's' }`
        this.rutas[1].titulo = `Estadisticas ${ this.$config.cliente + 's' }`
        this.rutas[2].titulo = `Lista de cupones ${ this.$config.vendedor + 's' }`
        this.rutas[3].titulo = `Lista de cupones ${ this.$config.vendedor + 's' }`
    }
}
</script>

<style lang="scss" scoped>
.change-view{
    border-radius: 12px;
    .right{
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
    }
    .left{
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
    }
}
</style>
