<template>
    <section>
        <div style="height:calc(100vh - 93px);">
            <div class="row mx-0 align-items-center pb-2">
                <div class="col-auto f-600 f-18 px-0">
                    <router-link :to="{name: 'admin.cedis.lista'}" class="text-general d-middle" style="text-decoration:none;">
                        <div class="bg-general3 rounded-circle d-middle-center mr-2" style="width:25px;height:25px;">
                            <i class="icon-left f-10 text-white" />
                        </div>
                        {{ info_cedis.nombre }}
                    </router-link>
                </div>
                <div class="col-auto text-general px-2 f-400 d-middle">
                    {{ agregarSeparadoresNumero(info_cedis.valores.calificacion) }}
                    <div class=" br-12 px-2 mx-1">
                        <i class="icon-star text-yellow f-18" />
                    </div>
                </div>
            </div>
            <tabs :tabs="rutas" class="border-bottom" />
            <div class="mx-0 pt-3">
                <router-view />
            </div>
        </div>
        <!-- Patials -->
        <modal-informacion-cedis ref="InformacionCedis" />
    </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    components: {
        modalInformacionCedis: () => import('../partials/modalInformacionCedis')
    },
    data(){
        return {
            rutas: [
                { titulo: 'Productos', ruta: 'admin.cedis.productos', },
                { titulo: 'Categorías', ruta: 'admin.cedis.categorias', can: 'tab_1_ver_cedis_categorias' },
                { titulo: 'Promociones', ruta: 'admin.cedis.promociones', can: 'tab1_ver_cedis_promociones' },
                //{ titulo: 'Comisiones', ruta: 'admin.cedis.comisiones', can: 'tab1_ver_cedis_comisiones' },
                { titulo: 'Comisiones y descuentos', ruta: 'admin.cedis.alianzas', can: 'tab1_ver_cedis_alianzas' },
                { titulo: 'Kits de productos',ruta: 'admin.cedis.combos-kits' },
                { titulo: 'Cupones', ruta: 'admin.cedis.cupones'},
                { titulo: 'Ordenes de compra', ruta: 'admin.cedis.ordenes-compra' },
                { titulo: 'Estadisticas', ruta: 'admin.cedis.estadisticas', can: 'tab1_ver_cedis_estadisticas'},
                { titulo: 'Informacion', ruta: 'admin.cedis.informacion', can: 'tab1_ver_cedis_estadisticas'},
                //{ titulo: 'Supercedis', ruta: 'admin.cedis.supercedis.main'},
            ]
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            info_cedis:'cedis/info_cedis',
        })
    },
    watch: {
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cedisInfo()
        },
    },
    mounted(){
        this.cedisInfo()
    },
    methods: {
        informacionCedis(){
            this.$refs.InformacionCedis.toggle();
        },
        cedisInfo(){
            if (this.id_moneda == null){
                setTimeout(() => {
                    let params = {
                        id_cedis: this.$route.params.id_cedis,
                        id_moneda: this.id_moneda,
                    }
                    this.$store.dispatch('cedis/action_info_cedis',params)
                    //this.$store.commit('cedis/set_id_cedis', this.$route.params.id_cedis)

                }, 1000);
            } else {
                let params = {
                    id_cedis: this.$route.params.id_cedis,
                    id_moneda: this.id_moneda,
                }
                this.$store.dispatch('cedis/action_info_cedis',params)
                //this.$store.commit('cedis/set_id_cedis', this.$route.params.id_cedis)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
.br-t-12{ border-radius: 12px 12px 0px 0px !important; }
.bg-smoke{ background-color: #5D5D5D; }
.text-yellow{ color: #FF9D32; }
</style>
