<template>
    <section style="height:calc(100vh - 182px);">
        <div class="row mx-0 h-100">
            <div class="bg-white br-10 overflow-auto custom-scroll select-prom">
                <content-loader v-if="loading" />
                <div
                v-for="(menu, idx) in buscadorPromociones" :key="idx"
                class="row mx-0 d-middle cr-pointer"
                style="height:230px;"
                @click="activarCar(menu)"
                >
                    <div :class="`mx-2 px-1 h-90 br-5 ${id_promocion==menu.id_promocion?'bg-general':''}`" />
                    <div :class="`col-11 tres-puntos border-bottom px-1 f-15 py-2 ${id_promocion==menu.id_promocion?'':''}`">
                        <div class="row mx-0">
                            <img class="obj-cover br-10 w-100 shadow" height="130" :src="menu.imagen" alt="" />
                        </div>
                        <div class="row mx-0 mt-2">
                            <p class="text-general my-auto ml-2 f-600 f-15">{{ menu.nombre }}</p>
                        </div>
                        <div class="row mx-0 justify-content-between">
                            <p class="tres-puntos my-auto ml-2 text-general"><b>Tipo:</b> {{ menu.tipo_show }}</p>
                            <el-tooltip v-if="menu.id_cedi_promocion && menu.pro_agregados>0" class="item" effect="light" placement="bottom">
                                <div slot="content" class="text-center" style="max-width:135px;">
                                    Productos dentro de la promoción
                                </div>
                                <div class="br-10 text-white px-2 f-17 my-auto bg-general3">
                                    <i class="icon-package-variant-closed f-18 mr-2" />
                                    <span>{{ menu.pro_agregados }}</span>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="row mx-0">
                            <i class="icon-calendar-clock text-general mr-1 f-20" :class="`${menu.id_cedi_promocion?'text-general2':''}`" />
                            <p v-if="menu.id_cedi_promocion" class="tres-puntos my-auto text-general">Vigencia: {{ formatearFecha(menu.inicio, 'D MMM') }} - {{ formatearFecha(menu.fin, 'D MMM') }}</p>
                            <span v-else class="my-auto">Sin fecha asignada</span>
                        </div>
                    </div>
                </div>
                <sinDatos v-if="buscadorPromociones.length <= 0 && !loading" icon="sale" mensaje="No hay promociones para gestionar." />
            </div>
            <div class="px-0 h-100 detail-product br-t-10 bg-white ml-3">
                <detalleProducto v-show="id_promocion" ref="detalleProducto" />
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    metaInfo: () =>({ title: 'Promociones'}),
    components: {
        detalleProducto: () => import('~/pages/cedis/ver/promociones/components/detalleProducto')
    },
    data(){
        return {
            id_cedi: this.$route.params.id_cedis,
            buscar: null,
            // activo: -1,
            loading: false

        }
    },
    computed:{
        ...mapGetters({
            id_promocion: 'promociones/promociones_cedis/id_promocion',
            promo_cedis: 'promociones/promociones_cedis/promo_cedis',
            info_cedis:'cedis/info_cedis',
        }),
        buscadorPromociones(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.promo_cedis.filter(el => el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))

            }else{
                return this.promo_cedis
            }
        },
        rutaCedis(){
            return this.$route.name == 'admin.cedis.productos' || this.$route.name == 'tendero.productos'
        }
    },
    mounted(){
        this.limpiarPromocion()
        this.listarPromociones()
    },
    methods: {
        crearPromocion(){
            this.$refs.modalCrearPromocion.toggle()
        },
        async listarPromociones(){
            try {
                const enviar = {
                    id_cedis: this.id_cedi
                }
                this.loading = true
                await this.$store.dispatch('promociones/promociones_cedis/action_listar_admin_promociones', enviar)
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        activarCar(menu){
            this.$refs.detalleProducto.resetear()
            this.$store.commit('promociones/promociones_cedis/set_id_promocion', menu.id_promocion)
            this.$store.commit('promociones/promociones_cedis/set_tipo_promocion', menu.tipo)
            this.$store.commit('promociones/promociones_cedis/set_promocion', menu)
            this.listarProductosAgregados()
            this.detalle_promo()
        },
        async listarProductosAgregados(){
            try {
                this.$store.commit('promociones/promociones_cedis/set_loading_general', true)
                const enviar ={
                    id_promocion: this.id_promocion,
                    id_cedi: this.id_cedi
                }
                await this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', enviar )
                this.$store.commit('promociones/promociones_cedis/set_loading_general', false)
            } catch (e){
                this.error_catch(e)
            }
        },
        async detalle_promo(){
            try {
                await this.$store.dispatch('promociones/promociones_cedis/action_detalle_promo', {id_promocion:this.id_promocion})
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarPromocion(){
            this.$store.commit('promociones/promociones_cedis/set_id_promocion', null)
            const datos ={
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias:1,
                visible: 1,
                promo_categorias:[]
            }
            this.$store.commit('promociones/promociones_cedis/set_detalle_promo', datos)
            this.$store.commit('promociones/promociones_cedis/set_productos', [])
        },
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}

.select-prom{
    width:400px;
    height: calc(100% - 1px);
}

.detail-product{
    width: calc(100vw - 536px)
}

@media (max-width: 1182px) {
  .select-prom{
    width:331px;
}
.detail-product{
    width: calc(100vw - 442px)
}
}
</style>
